// PaginatedTable.js
import React, { useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { useTheme } from '../Contexts/ThemeContext';

const PaginatedTable = ({ columns, data }) => {
  const { theme } = useTheme(); 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    usePagination
  );

  const visiblePageRange = 5; // Set the number of visible pages

  const pageOptions = useMemo(() => {
    const start = Math.max(0, pageIndex - Math.floor(visiblePageRange / 2));
    const end = Math.min(pageCount, start + visiblePageRange);

    return Array.from({ length: end - start }, (_, index) => start + index + 1);
  }, [pageIndex, pageCount, visiblePageRange]);

  return (
    <div className={`pagination-table ${theme}`}>
      <Table striped bordered hover {...getTableProps()} style={{ width: '100%', overflowX: 'auto' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const isHighTargets = row.values['Target'] >= 1;
            const isLowTargets = row.values['Target'] <= -1;

            return (
              <tr
                {...row.getRowProps()}
                className={isHighTargets ? 'high-targets' : isLowTargets ? 'low-targets' : ''}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {/* Check if the column is 'Target' and format the number */}
                    {cell.column.id === 'Target'
                      ? `${(row.values[cell.column.id] * 100).toFixed(2)}%`  // Convert to percentage
                      : cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.First onClick={() => gotoPage(0)} disabled={pageIndex === 0} />
        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        {pageOptions.map((index) => (
          <Pagination.Item
            key={index}
            active={index - 1 === pageIndex}
            onClick={() => gotoPage(index - 1)}
          >
            {index}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={pageIndex === pageCount - 1} />
      </Pagination>

      <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageCount}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '50px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[15, 25, 50, 100].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default PaginatedTable;
