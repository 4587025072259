// About.js
import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Page</h2>
      {/* About page content goes here */}
    </div>
  );
};

export default About;
