import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ThemeToggleButton from './Components/ThemeToggleButton';
import { ThemeProvider, useTheme } from './Contexts/ThemeContext';
import Home from './Pages/Home';
import About from './Pages/About';
import NotFound from './Pages/NotFound';
import DropdownMenu from './Components/DropdownMenu';

function AppWrapper() {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    );
}

function App() {
  const { theme } = useTheme(); // Use theme from context

  return (
    <div className={theme}> {/* Apply theme class to a top-level div */}
      <Router>
        <DropdownMenu />
        <ThemeToggleButton />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default AppWrapper; // Export the wrapper to encapsulate ThemeProvider
