import React from 'react';
import { useTheme } from '../Contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useTheme();
  const icon = theme === 'light' ? faMoon : faSun;

  return (
    <button onClick={toggleTheme} className="theme-toggle-button" aria-label="Toggle theme">
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

export default ThemeToggleButton;
