// Home.js
import React, { useState, useEffect } from 'react';
import PaginatedTable from '../Components/PaginatedTable';
import Search from '../Components/Search';
import { useTheme } from '../Contexts/ThemeContext'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'; 

const lambdaUrl = process.env.REACT_APP_RDS_LAMBDA_ENDPOINT;

const columns = [
  {
    Header: 'Name',
    accessor: 'Name',
  },
  {
    Header: 'Position',
    accessor: 'POS',
  },
  {
    Header: 'Club',
    accessor: 'Club',
  },
  {
    Header: 'Status',
    accessor: 'Status',
  },
  {
    Header: 'Price',
    accessor: 'Price',
  },
  {
    Header: '% Owned',
    accessor: '% Owned',
  },
  {
    Header: 'Net Transfers',
    accessor: 'Net Transfers',
  },
  {
    Header: 'Target',
    accessor: 'Target',
  },
];

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(lambdaUrl);
        const result = await response.json();
        setData(
          result.map(item => ({
            Name: item[0],
            Club: item[2],
            POS: item[1],
            Status: item[3],
            '% Owned': item[5],
            Price: item[4],
            'Net Transfers': item[6],
            Target: item[7],
          }))
        );
      } catch (error) {
        console.error('Error fetching data. Run app locally to view error');
        // console.log(error) ONLY UNCOMMENT THIS WHEN DEBUGGING. AFTER FINISHING DEBUG, COMMENT THIS OUT
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = data.filter(
    item => item.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="Home">
      <div className="Search-container">
        <h2>Price Change Predictions</h2>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          className="Search-input"
        />
      </div>
      <div className="Home-content">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <PaginatedTable key={theme} columns={columns} data={filteredData} className="Table" />
        )}
      </div>
    </div>
  );
}

export default Home;
