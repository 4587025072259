import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div>
      <button onClick={toggleDropdown} className="dropdown-icon">
        ☰ {/* This is a simple icon representing the menu, you can use an image or an SVG instead */}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <Link to="/" onClick={toggleDropdown}>Home</Link>
          <Link to="/about" onClick={toggleDropdown}>About</Link>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;
